.error {
  color: red;
}

.Newsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Newsletter form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  background: white;
  width: 70%;
  padding: 2em;
  margin: 1em;
  border-radius: 0.5em;
}
.Newsletter h1 {
  color: white;
}
.Newsletter p {
  color: white;
  text-align: center;
  font-size: 1.3em;
}
.Newsletter form input {
  padding: 0.8em;
  margin: 0.4em;
  width: 100%;
  font-size: 1em;
}
/* mobile news letter*/
.NewsletterMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.NewsletterMobile form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  background: white;
  width: 70%;
  padding: 1em;
  margin: 1em;
  border-radius: 0.5em;
}
.NewsletterMobile h1 {
  color: white;
}
.NewsletterMobile p {
  color: white;
  text-align: center;
  font-size: 1.1em;
}
.NewsletterMobile form input {
  padding: 0.8em;
  margin: 0.4em;
  width: 100%;
  font-size: 1em;
}
.NewsletterMobile h1 {
  font-size: 1.3em;
}
