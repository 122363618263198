.Success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(0, 78, 152);
  color: white;
}

.logo {
  width: 40%;
}
