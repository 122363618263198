.Order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}

.logo {
  width: 40%;
}
.status {
  font-size: 1.3em;
  padding: 0.5em;
  margin: 0;
  border-radius: 0.5em;
  background-color: rgb(0, 78, 152);
}
.status > span {
  display: block;
  margin: 0.5em;
  color: white;
}
.item {
  font-size: 0.9em;
}

/* mobile tracking*/
.OrderMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}
.OrderMobile .status {
  width: 85vw;
  text-align: center;
  font-size: 1.3em;
}
