.Maintenance{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100vh;
    background-color: rgb(0, 78, 152);
    color:white;
}
.Maintenance .Logo{
    width:50vh;

}
.Maintenance h1{
    margin-left: 2em;
}