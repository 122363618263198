@import url(https://fonts.googleapis.com/css2?family=Muli:wght@300&display=swap);
html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Muli", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  cursor: pointer;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  color: white;
}

input,
textarea,
select {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 0.5em;
}
button {
  border-radius: 0.5em;
}
p {
  word-wrap: break-word;
  white-space: pre-wrap;
}
li {
  list-style: none;
}
.ReactModal__Content {
  background-color: rgb(0, 78, 152) !important ;
  border-radius: 0.5em !important;
  overflow-x: hidden !important;
}

.button_transparent__2X5pm {
  background-color: Transparent;
}

.button_info__W8dHW {
  background-color: rgb(0, 78, 152);
  color: white;
  padding: 1em;
  margin: 0.5em;
}
.button_light__HfI4N {
  background-color: white;
  color: black;
}
.button_danger__16ajd {
  background-color: grey;
  color: white;
  padding: 1em;
}
.button_default__2rvsl {
  background-color: transparent;
  color: black;
  padding: 1em;
  font-size: 1em;
  text-decoration: underline;
}
.button_block__1PnMv {
  width: 100%;
}
.button_disabled__3nl3V {
  background-color: grey;
  cursor: initial;
}

.header_Header__1hmiM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(0, 78, 152);
  padding: 1em 1.5em 1em 1.5em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000000;
}
.header_Header__1hmiM > div:nth-of-type(1) {
  display: flex;
  justify-content: center;
}

.header_Header__1hmiM .header_logo__6pbmi {
  max-height: 100%;
  width: 10em;
}
.header_Header__1hmiM .header_cartLogo__cue3E {
  color: white;
  text-align: center;
  vertical-align: middle;
  position: relative;
}
.header_Header__1hmiM .header_badge__xvE9h {
  background: red;
  position: absolute;
  top: 1.4em;
  right: 1.7em;
  color: #ffffff;
  padding: 0.5em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.7em;
  border-radius: 50%;
  text-align: center;
}

.header_Header__1hmiM .header_timer__6_mqI {
  font-size: 1.5em;
  color: white;
  position: absolute;
  top: 0.9em;
  right: 48vw;
}
.header_Header__1hmiM .header_logoContainer__1OFQh {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}
.header_Header__1hmiM .header_logoContainer__1OFQh > p {
  background-color: white;
  padding: 0.1em 0.5em 0.1em 0.5em;
  font-size: 0.7em;
  border-radius: 0.5em;
  position: absolute;
  top: -1.5em;
  right: 0.09em;
}
.header_Header__1hmiM .header_controlContainer__3uQ7q button:first-of-type {
  margin-left: 1em;
}
.header_Header__1hmiM .header_controlContainer__3uQ7q button {
  padding: 1em;
  font-size: 1em;
}
.header_Header__1hmiM .header_controlContainer__3uQ7q button svg {
  margin-right: 0.3em;
}
.header_Header__1hmiM .header_form__10k1G {
  display: flex;
  position: absolute;
  right: 30vw;
  top: 2.5vh;
  width: 30vw;
}
.header_Header__1hmiM .header_form__10k1G input {
  padding: 0.8em;
  width: 100%;
  font-size: 1em;
}
.header_Header__1hmiM .header_form__10k1G button {
  position: absolute;
  right: -1em;
  top: -0.4em;
}
.header_Header__1hmiM .header_form__10k1G button svg {
  color: rgb(0, 78, 152);
}
/* Header Mobile */
.header_HeaderMobile__1R18_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(0, 78, 152);
  padding: 1em 1.5em 1em 1.5em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000000;
  height: 100%;
}

.header_HeaderMobile__1R18_ .header_logoContainer__1OFQh {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_HeaderMobile__1R18_ .header_logo__6pbmi {
  max-height: 100%;
  width: 10em;
}

.header_HeaderMobile__1R18_ .header_logoContainer__1OFQh > p {
  background-color: white;
  padding: 0.1em 0.5em 0.1em 0.5em;
  font-size: 0.7em;
  border-radius: 0.5em;
  position: absolute;
  top: -0.3em;
  right: 28vw;
}

.header_HeaderMobile__1R18_ .header_controlContainer__3uQ7q {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header_HeaderMobile__1R18_ .header_controlContainer__3uQ7q button {
  margin: 1em auto;
  font-size: 1em;
}

.header_HeaderMobile__1R18_ .header_controlContainer__3uQ7q button svg {
  margin-right: 0.3em;
}

.header_HeaderMobile__1R18_ .header_burger__3DaqT {
  color: white;
  position: absolute;
  top: 0.7em;
  right: 1em;
  cursor: pointer;
}

.header_HeaderMobile__1R18_ .header_cartLogo__cue3E {
  background-color: lightgray;
  padding: 0.3em;
  border-radius: 50%;
  color: rgb(0, 78, 152);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  bottom: 0.2em;
  right: 0.6em;
  font-size: 2.5em;
}

.header_HeaderMobile__1R18_ .header_badge__xvE9h {
  background: red;
  position: fixed;
  bottom: 2.5em;
  right: 0.8em;
  color: #ffffff;
  padding: 0.5em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.7em;
  border-radius: 50%;
  text-align: center;
  font-size: 1em;
}

.header_HeaderMobile__1R18_ .header_timer__6_mqI {
  font-size: 1.5em;
  color: white;
  position: absolute;
  top: 1em;
  left: 1em;
}

.header_HeaderMobile__1R18_ .header_form__10k1G {
  display: flex;
  position: relative;
}
.header_HeaderMobile__1R18_ .header_form__10k1G input {
  padding: 0.8em;
  width: 100%;
  font-size: 1em;
}
.header_HeaderMobile__1R18_ .header_form__10k1G button {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
}
.header_HeaderMobile__1R18_ .header_form__10k1G button svg {
  color: rgb(0, 78, 152);
}

.landing_Landing__2UGY6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.landing_Landing__2UGY6 svg {
  color: black;
}

.modal_modalHeader__3WtSs {
  display: flex;
  justify-content: center;
  color: white;
  overflow-x: hidden;
}
.modal_modalBody__2i7HS {
  padding: 1em;
}
.modal_btnClose__1oJzt {
  background-color: transparent;
  padding: 1em;
  top: -1em;
  right: -0.8em;
  position: absolute;
}
.modal_errorMessage__LoDl_ {
  color: red;
}
.modal_modalHeaderMobile__dOzki {
  display: flex;
  justify-content: center;
  color: white;
  overflow-x: hidden;
}
.modal_modalHeaderMobile__dOzki h2 {
  color: white;
  font-size: 1.3em;
  text-align: center;
}

.newsletter_error__3Tlu8 {
  color: red;
}

.newsletter_Newsletter__rUX60 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newsletter_Newsletter__rUX60 form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  background: white;
  width: 70%;
  padding: 2em;
  margin: 1em;
  border-radius: 0.5em;
}
.newsletter_Newsletter__rUX60 h1 {
  color: white;
}
.newsletter_Newsletter__rUX60 p {
  color: white;
  text-align: center;
  font-size: 1.3em;
}
.newsletter_Newsletter__rUX60 form input {
  padding: 0.8em;
  margin: 0.4em;
  width: 100%;
  font-size: 1em;
}
/* mobile news letter*/
.newsletter_NewsletterMobile__1DNYe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.newsletter_NewsletterMobile__1DNYe form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  background: white;
  width: 70%;
  padding: 1em;
  margin: 1em;
  border-radius: 0.5em;
}
.newsletter_NewsletterMobile__1DNYe h1 {
  color: white;
}
.newsletter_NewsletterMobile__1DNYe p {
  color: white;
  text-align: center;
  font-size: 1.1em;
}
.newsletter_NewsletterMobile__1DNYe form input {
  padding: 0.8em;
  margin: 0.4em;
  width: 100%;
  font-size: 1em;
}
.newsletter_NewsletterMobile__1DNYe h1 {
  font-size: 1.3em;
}

.tracking_Tracking__2bPdL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(0, 78, 152);
  color: white;
}

.tracking_logo__3Uf_L {
  width: 40%;
}
.tracking_status__1QyFv {
  font-size: 1.3em;
  padding: 0.5em;
  margin: 0;
  background-color: white;
  border-radius: 0.5em;
}
.tracking_status__1QyFv > span {
  display: block;
  margin: 0.5em;
  color: black;
}
.tracking_item__1E3js {
  font-size: 0.9em;
}

/* mobile tracking*/
.tracking_TrackingMobile__1MIDG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(0, 78, 152);
  color: white;
}
.tracking_TrackingMobile__1MIDG .tracking_status__1QyFv {
  width: 85vw;
  text-align: center;
  font-size: 1.3em;
}

.order_Order__2y9aR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}

.order_logo__38ET3 {
  width: 40%;
}
.order_status__3EwsX {
  font-size: 1.3em;
  padding: 0.5em;
  margin: 0;
  border-radius: 0.5em;
  background-color: rgb(0, 78, 152);
}
.order_status__3EwsX > span {
  display: block;
  margin: 0.5em;
  color: white;
}
.order_item__bqXfr {
  font-size: 0.9em;
}

/* mobile tracking*/
.order_OrderMobile__1bIdd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}
.order_OrderMobile__1bIdd .order_status__3EwsX {
  width: 85vw;
  text-align: center;
  font-size: 1.3em;
}

.notification_Success__3Ra-p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(0, 78, 152);
  color: white;
}

.notification_logo__16OQf {
  width: 40%;
}

.app_App__2qw4I {
  display: grid;
  grid-template-rows: 10% 80%;
  text-align: center;
  background-color: white;
  width: 100vw;
}
.app_App__2qw4I > :nth-of-type(2) {
  height: 100vh;
}

.maintenance_Maintenance__olkeD{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:100vh;
    background-color: rgb(0, 78, 152);
    color:white;
}
.maintenance_Maintenance__olkeD .maintenance_Logo__3KNaE{
    width:50vh;

}
.maintenance_Maintenance__olkeD h1{
    margin-left: 2em;
}
