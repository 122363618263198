.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(0, 78, 152);
  padding: 1em 1.5em 1em 1.5em;
  position: sticky;
  top: 0;
  z-index: 1000000;
}
.Header > div:nth-of-type(1) {
  display: flex;
  justify-content: center;
}

.Header .logo {
  max-height: 100%;
  width: 10em;
}
.Header .cartLogo {
  color: white;
  text-align: center;
  vertical-align: middle;
  position: relative;
}
.Header .badge {
  background: red;
  position: absolute;
  top: 1.4em;
  right: 1.7em;
  color: #ffffff;
  padding: 0.5em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.7em;
  border-radius: 50%;
  text-align: center;
}

.Header .timer {
  font-size: 1.5em;
  color: white;
  position: absolute;
  top: 0.9em;
  right: 48vw;
}
.Header .logoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}
.Header .logoContainer > p {
  background-color: white;
  padding: 0.1em 0.5em 0.1em 0.5em;
  font-size: 0.7em;
  border-radius: 0.5em;
  position: absolute;
  top: -1.5em;
  right: 0.09em;
}
.Header .controlContainer button:first-of-type {
  margin-left: 1em;
}
.Header .controlContainer button {
  padding: 1em;
  font-size: 1em;
}
.Header .controlContainer button svg {
  margin-right: 0.3em;
}
.Header .form {
  display: flex;
  position: absolute;
  right: 30vw;
  top: 2.5vh;
  width: 30vw;
}
.Header .form input {
  padding: 0.8em;
  width: 100%;
  font-size: 1em;
}
.Header .form button {
  position: absolute;
  right: -1em;
  top: -0.4em;
}
.Header .form button svg {
  color: rgb(0, 78, 152);
}
/* Header Mobile */
.HeaderMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(0, 78, 152);
  padding: 1em 1.5em 1em 1.5em;
  position: sticky;
  top: 0;
  z-index: 1000000;
  height: 100%;
}

.HeaderMobile .logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HeaderMobile .logo {
  max-height: 100%;
  width: 10em;
}

.HeaderMobile .logoContainer > p {
  background-color: white;
  padding: 0.1em 0.5em 0.1em 0.5em;
  font-size: 0.7em;
  border-radius: 0.5em;
  position: absolute;
  top: -0.3em;
  right: 28vw;
}

.HeaderMobile .controlContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HeaderMobile .controlContainer button {
  margin: 1em auto;
  font-size: 1em;
}

.HeaderMobile .controlContainer button svg {
  margin-right: 0.3em;
}

.HeaderMobile .burger {
  color: white;
  position: absolute;
  top: 0.7em;
  right: 1em;
  cursor: pointer;
}

.HeaderMobile .cartLogo {
  background-color: lightgray;
  padding: 0.3em;
  border-radius: 50%;
  color: rgb(0, 78, 152);
  text-align: center;
  vertical-align: middle;
  position: fixed;
  bottom: 0.2em;
  right: 0.6em;
  font-size: 2.5em;
}

.HeaderMobile .badge {
  background: red;
  position: fixed;
  bottom: 2.5em;
  right: 0.8em;
  color: #ffffff;
  padding: 0.5em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 0.7em;
  border-radius: 50%;
  text-align: center;
  font-size: 1em;
}

.HeaderMobile .timer {
  font-size: 1.5em;
  color: white;
  position: absolute;
  top: 1em;
  left: 1em;
}

.HeaderMobile .form {
  display: flex;
  position: relative;
}
.HeaderMobile .form input {
  padding: 0.8em;
  width: 100%;
  font-size: 1em;
}
.HeaderMobile .form button {
  position: absolute;
  right: -0.5em;
  top: -0.5em;
}
.HeaderMobile .form button svg {
  color: rgb(0, 78, 152);
}
