@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300&display=swap");
html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Muli", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  cursor: pointer;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  color: white;
}

input,
textarea,
select {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 0.5em;
}
button {
  border-radius: 0.5em;
}
p {
  word-wrap: break-word;
  white-space: pre-wrap;
}
li {
  list-style: none;
}
.ReactModal__Content {
  background-color: rgb(0, 78, 152) !important ;
  border-radius: 0.5em !important;
  overflow-x: hidden !important;
}
