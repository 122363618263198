.App {
  display: grid;
  grid-template-rows: 10% 80%;
  text-align: center;
  background-color: white;
  width: 100vw;
}
.App > :nth-of-type(2) {
  height: 100vh;
}
