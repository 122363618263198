.modalHeader {
  display: flex;
  justify-content: center;
  color: white;
  overflow-x: hidden;
}
.modalBody {
  padding: 1em;
}
.btnClose {
  background-color: transparent;
  padding: 1em;
  top: -1em;
  right: -0.8em;
  position: absolute;
}
.errorMessage {
  color: red;
}
.modalHeaderMobile {
  display: flex;
  justify-content: center;
  color: white;
  overflow-x: hidden;
}
.modalHeaderMobile h2 {
  color: white;
  font-size: 1.3em;
  text-align: center;
}
