.transparent {
  background-color: Transparent;
}

.info {
  background-color: rgb(0, 78, 152);
  color: white;
  padding: 1em;
  margin: 0.5em;
}
.light {
  background-color: white;
  color: black;
}
.danger {
  background-color: grey;
  color: white;
  padding: 1em;
}
.default {
  background-color: transparent;
  color: black;
  padding: 1em;
  font-size: 1em;
  text-decoration: underline;
}
.block {
  width: 100%;
}
.disabled {
  background-color: grey;
  cursor: initial;
}
